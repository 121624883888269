<template>
    <nav>
        <div class="top-nav">
            <logo />
        </div>
        
    </nav>
    <div class="logout-page">
        <div>
            <div class="logout-page__loader"><filling-circle  v-if="loading" color="#057DCD" :size="42" /></div>
            <div class="logout">
                <!-- <img src="@/assets/images/logo.png" alt="" class="logout__logo"> -->
                <h1 class="logout__heading">Log out of Vendoorr?</h1>
                <p class="logout__info">You can always log back in at any time. </p>
                <div class="modal__buttons-container">
                    <button class="modal__button" @click="cancel">cancel</button>
                    <button class="modal__button modal__button--delete" @click="logOut">logout</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Logo from '@/components/Logo.vue'
import FillingCircle from '@/components/Loaders/FillingCircle.vue'
export default {
    data(){
        return{
            loading:false,
        }
    },
     components:{
        Logo,
        FillingCircle
    },
    methods:{
        logOut(){
            this.loading = true 
            this.$store.dispatch({
            type:'logOut',
            }).then(()=>{
                this.loading = false
                this.$notify({
                    title:"Logged Out",
                    text:"Successfully logged out of your account",
                    type:"success"
                });
                this.$router.push({name:'Login'})
            }).catch((error)=>{
                this.loading = false
                this.$notify({
                    title: "Logout Error",
                    text: "There was a problem logging out",
                    type:"error"
                });
                console.log(error)
            })
        },
        cancel(){
            this.$router.go(-1)
        }
    }
}
</script>

<style>
    .logout-page{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .logout{
        
        text-align: center;
        background-color: #fff;
        margin:1em;
        padding: 2em 3em ;
        border-radius: 8px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
    .logout__heading{
        font-size: 1.75em;
        font-weight: bold;
    }
    .logout__info,.logout__heading{
        margin-bottom: 2rem;
    }
    .logout__logo{
        width:72px;
    }
    .logout-page__loader{
        min-height: 42px;
        display: flex;
        justify-content: center;
        margin-bottom: 1em;
    }
</style>