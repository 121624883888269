<template>
    <nav class> 
        <div class="top-nav">
            <logo />
        </div>
        
    </nav>
    <div class="login-page">
        <div class="login">
            <div class="login__illustration">
                <div style="text-align:center;">
                    <img class="login__image" src="@/assets/images/login.png" alt="">
                </div>
            </div>

            <div class="login__area-container">
                <div class="login__area">
                   
                    <div class="login__top">
                        <h1 class="login__heading">Login</h1><a href="" class="login__register-link">sign up</a>
                    </div>
                     

                    <input v-model="username" @keyup.enter="logIn" class="login__username" placeholder="username" type="text">
                    <input v-model="password" @keyup.enter="logIn" class="login__password" placeholder="password" type="password">
                    <button class="login__button" @click="logIn" :disabled="checking || !allFormsFilled">
                        <div v-if="checking"><filling-circle :size="16" /></div>
                        <div>{{ checking ? 'Checking...' : 'Login' }}</div>
                    </button>
                    <a class="login__forgot">forgot password?</a>
                </div>
                <!-- <div class="login__footer">Vendoorr Technologies &copy;2021 </div> -->
            </div>
        </div>
    </div>

</template>
<script>

import Logo from '@/components/Logo.vue'
import FillingCircle from '@/components/Loaders/FillingCircle.vue'
export default {
    components:{
        Logo,
        FillingCircle
    },

    data(){
        return {
            checking:false,
            username:"",
            password:""
        }
    },

    methods:{
        logIn(){
            if(this.allFormsFilled){
                this.checking = true
                this.$store.dispatch({
                    type:'logIn',
                    username: this.username,
                    password: this.password    
                })
                .then(()=>{
                    if(this.redirectPath) this.$router.push({path: this.redirectPath})
                    else this.$router.push({name: 'Home'})
                    this.checking = false
                    this.$notify({
                        title: "Logged In",
                        text:"Account authentication successful",
                        type:"success"
                    })
                })
                .catch(() => {
                    this.checking = false
                    this.$notify({
                        title: "Login Error",
                        text: "Wrong User or Password",
                        type:"error"
                    })
                })
            }
        }
    },
    computed:{
        allFormsFilled(){
            return (this.username && this.password)
        },
        redirectPath(){
            return (this.$route.query.redirect)
        },
        isVendor(){ return this.$store.state.user.user_type === 'Vendor' },
		branchId(){ return this.$store.state.branchId },
        },
        mounted(){
            this.$store.commit({type:'stopAuthenticationLoader'})
        }
}
</script>
<style>
    .login-page{
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        padding:0 1em;
        overflow-y: scroll;

    }

    .login{
        display: grid;
        grid-template-columns: repeat( auto-fit, minmax(500px, 1fr) );
        margin-bottom: 2em;
    }
    .login__illustration{
        padding:2em;
    }
    .login__image{
        width: 100%;
    }

    .login__area-container{
        display: flex;
        align-items: center;
        justify-content: center;
 
    }

    .login__area{
        flex:1;
        max-width: 440px;
        background:#FFF;
    
        padding:3em;
        border-radius: 8px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
       
    }
    .login__username,.login__password{
        /* Remove First */
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        font-size: 1em;
        background:#FFF;
        display: block;
 
        width:100%;
        padding: 15px;
        border-radius: var(--border-radius);
        box-shadow: var(--shadow-input);

        margin-bottom:2.5em;
    }
    .login__button{
        display: flex;
        justify-content: center;
        /* change to margin */
        gap:1em;

        color:#fff;
        font-weight: bold;
        font-size: 1em;
        background:var(--bg-dark);
        border:none;
        width:100%;
        padding: 15px;
        border-radius: var(--border-radius);
        box-shadow: var(--shadow-input);

        margin-top:1em;
        margin-bottom:1em;
    }
    .login__button[disabled]{
        cursor: not-allowed;
        opacity: .9;
    }
    .login__heading{
        font-size: 1.5em;
        font-weight: bold;
        color: var(--bg-dark);
    }
    .login__top{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 2em;
    }
    .login__register-link{
        margin-left: auto;
    }
    .login__remember{
        display: block;
        margin-bottom: 1.5em;
    }
    .login__forgot{
        display: block;
    }
    
    .login__error{
        width: 100%;
        position: relative;
        top:1em;
        text-transform: capitalize;
        text-align: center;
        background:rgba(255,92,97,.85);
        color: #fff;
        padding: .5em 1em;
        /* line-height: 1.3; */
        border-radius:var(--border-radius);
    }
    
    @media screen and (max-width: 768px){
        .login{
            grid-template-columns: 1fr;
        }
        .login__image{
            width: 60%;
        }

    }
</style>